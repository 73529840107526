<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="card-header" style="text-align:left">
                    <el-col :span="4" class="hidden-md-and-down">
                        <span>编辑商家券信息</span>
                    </el-col>
                </div>
            </template>
            <div class="content">
                <h1>基本信息</h1>
                <el-form :model="merchantcouponinfo" :rules="rules" ref="merchantcouponinfo" label-width="200px" class="pdtform" size="small">
                    <el-form-item label="商家券名称" prop="ActivityName">
                        <el-input v-model="merchantcouponinfo.ActivityName" class="input-size"></el-input>
                    </el-form-item>
                    <el-form-item label="码模式" prop="CodeMode">
                        <el-select v-model="merchantcouponinfo.CodeMode" class="input-size" placeholder="请选择">
                            <el-option label="商户上传自定义编码" value="1"></el-option>
                            <el-option label="发奖时指定券码发奖" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="券类型" prop="VoucherType">
                        <el-select v-model="merchantcouponinfo.VoucherType" class="input-size" placeholder="请选择" @change="ChangeVoucherType">
                            <el-option label="满减券" value="1"></el-option>
                            <el-option label="折扣券" value="2"></el-option>
                            <el-option label="特价券" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="merchantcouponinfo.FullMinus" label="可抵扣得金额" prop="Amount">
                        <el-input v-model="merchantcouponinfo.Amount" placeholder="单位：元" class="input-size"></el-input>
                        <span class="amount">取值范围:大于等于0.1，小于等于3000</span>
                    </el-form-item>
                    <el-form-item v-if="merchantcouponinfo.FullMinus" label="门槛金额" prop="FloorAmount">
                        <el-input v-model="merchantcouponinfo.FloorAmount" placeholder="单位：元" class="input-size"></el-input>
                        <span class="floor_amount">该字段不填写，认为无门槛，限制： 只有当订单金额大于等于门槛金额时券才能使用。设置门槛：取值范围:大于等于0.1，小于等于50000。</span>
                    </el-form-item>
                    <el-form-item v-if="merchantcouponinfo.FullMinus" label="商品名称" prop="GoodsName">
                        <el-input v-model="merchantcouponinfo.GoodsName" placeholder="商品名称" class="input-size"></el-input>
                    </el-form-item>
                    <el-form-item label="券发放开始时间" required>
                        <el-col :span="11">
                            <el-form-item style="margin-right:5px;" prop="PublishStartTime">
                                <el-date-picker v-model="merchantcouponinfo.PublishStartTime" type="datetime" placeholder="年/月/日"></el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="券发放结束时间" required>
                        <el-col :span="11">
                            <el-form-item style="margin-right:5px;" prop="PublishEndTime">
                                <el-date-picker v-model="merchantcouponinfo.PublishEndTime" type="datetime" placeholder="年/月/日"></el-date-picker>
                                <span class="publictime">发放与结束时间，间隔必须小于等于180天</span>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-divider />
                    <h1>券展示信息</h1>
                    <el-form-item label="商户品牌名称" prop="BrandName">
                        <el-input v-model="merchantcouponinfo.BrandName" class="input-size"></el-input>
                    </el-form-item>
                    <el-form-item label="商家logo" prop='PdtImg'>
                        <div style="display:flex;flex-direction:row">
                            <draggable
                                       class="vue-draggable"
                                       v-model="merchantcouponinfo.PdtImg"
                                       @start="onDragStart"
                                       @end="onDragEnd"
                                       item-key="index">
                                <template #item="{ element, index }">
                                    <div class="draggable-item">
                                        <el-image :src="element.url" :preview-src-list="[element.url]"></el-image>
                                        <div class="shadow" @click="onRemoveHandler(index)">
                                            <i class="el-icon-delete"></i>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                            <el-upload
                                       class="avatar-uploader"
                                       :action="upload_url"
                                       list-type="picture-card"
                                       :on-success="UploadSuccess"
                                       :on-change="imageUploadChange"
                                       :on-error="imgUploadError"
                                       :on-remove="RemovePic"
                                       :on-preview="Preview"
                                       accept=".jpg,.jpeg,.png"
                                       :show-file-list="false"
                                       multiple
                                       :file-list="FileList">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                        <div class="el-upload__tip2">
                            上传图片尺寸600*600，支持格式：png、jpg、jpeg、bmp，大小不超过2MB
                        </div>
                        <el-dialog v-model="dialogVisible">
                            <img style="width:100%" :src="dialogImageUrl" alt="" />
                        </el-dialog>
                    </el-form-item>
                    <el-form-item label="券详细使用说明" prop="VoucherDescription">
                        <el-input
                                  class="input-size"
                                  v-model="merchantcouponinfo.VoucherDescription"
                                  autosize
                                  type="textarea"
                                  placeholder="1、本券不可兑换现金，不可找零。2、每个用户最多可以领取1张。3、如果订单发生退款，优惠券无法退还。" />
                    </el-form-item>
                    <el-form-item label="券详情页封面图" prop="PdtImg2">
                        <div style="display:flex;flex-direction:row">
                            <draggable
                                       class="vue-draggable"
                                       v-model="merchantcouponinfo.PdtImg2"
                                       @start="onDragStart2"
                                       @end="onDragEnd2"
                                       item-key="index">
                                <template #item="{ element, index }">
                                    <div class="draggable-item">
                                        <el-image :src="element.url" :preview-src-list="[element.url]"></el-image>
                                        <div class="shadow" @click="onRemoveHandler2(index)">
                                            <i class="el-icon-delete"></i>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                            <el-upload
                                       class="avatar-uploader"
                                       :action="upload_url"
                                       list-type="picture-card"
                                       :on-success="UploadSuccess2"
                                       :on-change="imageUploadChange"
                                       :on-error="imgUploadError"
                                       :on-remove="RemovePic2"
                                       :on-preview="Preview2"
                                       accept=".jpg,.jpeg,.png"
                                       :show-file-list="false"
                                       multiple
                                       :file-list="FileList2">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                        <div class="el-upload__tip2">
                            上传图片尺寸670*335，支持格式：png、jpg、jpeg、bmp，大小不超过2MB
                        </div>
                        <el-dialog v-model="dialogVisible">
                            <img style="width:100%" :src="dialogImageUrl" alt="" />
                        </el-dialog>
                    </el-form-item>
                    <el-form-item label="券详细图列表" prop="PdtImg3">
                        <div style="display:flex;flex-direction:row">
                            <draggable
                                       class="vue-draggable"
                                       v-model="merchantcouponinfo.PdtImg3"
                                       @start="onDragStart3"
                                       @end="onDragEnd3"
                                       item-key="index">
                                <template #item="{ element, index }">
                                    <div class="draggable-item">
                                        <el-image :src="element.url" :preview-src-list="[element.url]"></el-image>
                                        <div class="shadow" @click="onRemoveHandler3(index)">
                                            <i class="el-icon-delete"></i>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                            <el-upload
                                       class="avatar-uploader"
                                       :action="upload_url"
                                       list-type="picture-card"
                                       :on-success="UploadSuccess3"
                                       :on-change="imageUploadChange"
                                       :on-error="imgUploadError"
                                       :on-remove="RemovePic3"
                                       :on-preview="Preview3"
                                       accept=".jpg,.jpeg,.png"
                                       :show-file-list="false"
                                       multiple
                                       :file-list="FileList3">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                        <div class="el-upload__tip3">
                            最多3张；上传图片尺寸600*600，支持格式：png、jpg、jpeg、bmp，大小不超过2MB
                        </div>
                        <el-dialog v-model="dialogVisible">
                            <img style="width:100%" :src="dialogImageUrl" alt="" />
                        </el-dialog>
                    </el-form-item>
                    <el-form-item label="客服信息" prop="CustomerServiceInformation">
                        <el-radio-group v-model="merchantcouponinfo.CustomerServiceInformation">
                            <el-radio label="1">客服电话</el-radio>
                            <el-radio label="2">客服链接</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="Service">
                        <el-input v-model="merchantcouponinfo.Service" class="input-size" placeholder="限制: 客服电话和客服链接必须二选一"></el-input>
                    </el-form-item>
                    <el-divider />
                    <h1>券核销规则</h1>
                    <el-form-item label="使用有效期" prop="Type">
                        <el-radio-group v-model="merchantcouponinfo.Type">
                            <el-radio label="1">相对期限</el-radio>
                            <el-radio label="2">固定期限</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div v-if="merchantcouponinfo.Type == 1">
                        <el-form-item label="券可使用的开始时间" prop="ValidBeginTime">
                            <el-date-picker v-model="merchantcouponinfo.ValidBeginTime" type="datetime" placeholder="券可使用的开始时间" prop='ValidBeginTime'></el-date-picker>
                        </el-form-item>
                        <el-form-item label='券可使用的结束时间' prop="ValidEndTime">
                            <el-date-picker v-model="merchantcouponinfo.ValidEndTime" type="datetime" placeholder="券可使用的结束时间" prop='ValidEndTime'></el-date-picker>
                            <span class="validendtime">券可使用的结束时间，必须大于券的发放结束时间</span>
                        </el-form-item>
                    </div>
                    <div v-if="merchantcouponinfo.Type == 2">
                        <el-form-item label="用户领券后需要等待" prop="ValidDaysAfterReceive">
                            <el-input v-model="merchantcouponinfo.ValidDaysAfterReceive" class="input-size"></el-input>
                            天，券才可以生效
                        </el-form-item>
                        <el-form-item label="券生效后" prop="WaitDaysAfterReceive">
                            <el-input v-model="merchantcouponinfo.WaitDaysAfterReceive" class="input-size"></el-input>
                            天内可以使用
                        </el-form-item>
                    </div>
                    <el-divider />
                    <h1>券发放规则</h1>
                    <el-form-item label="发行券的数量" prop="VoucherQuantity">
                        <el-input v-model="merchantcouponinfo.VoucherQuantity" class="input-size"></el-input>
                        <span class="voucher_quantity">商户上传自定义编码模式下数量必须为0或者选择不填。</span>
                    </el-form-item>
                    <el-form-item label="每人领取限制" prop="VoucherQuantityLimitPerUser">
                        <el-input v-model="merchantcouponinfo.VoucherQuantityLimitPerUser" class="input-size"></el-input>
                        <span class="voucher_quantity_limit_per_user">填写大于等于0的正整数；填入0时为没有领取限制. </span>
                    </el-form-item>
                    <el-form-item label="领(购)券详情页链接" prop="LinkDetails">
                        <el-input v-model="merchantcouponinfo.LinkDetails" class="input-size"></el-input>
                        <span class="link_details">如：alipays://platformapi/startapp?appId=xxxx....</span>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="back">取消</el-button>
                        <el-button type="primary" @click="submitForm('merchantcouponinfo')">保存</el-button>
                        <el-button type="primary" @click="saveCreate('merchantcouponinfo')">保存并创建</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>
<style lang="less" scoped>
.vue-draggable {
    display: flex;
    flex-wrap: wrap;
    .draggable-item {
        width: 148px;
        height: 148px;
        margin-right: 5px;
        margin-bottom: 5px;
        border: 1px solid #ddd;
        border-radius: 6px;
        position: relative;
        overflow: hidden;

        .el-image {
            width: 100%;
            height: 100%;
        }

        .shadow {
            position: absolute;
            top: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .5);
            opacity: 0;
            transition: opacity .3s;
            color: #fff;
            font-size: 20px;
            line-height: 20px;
            padding: 2px;
            cursor: pointer;
            border-bottom-left-radius: 6px;
        }

        &:hover {
            .shadow {
                opacity: 1;
            }
        }
    }

    &.hideShadow {
        .shadow {
            display: none;
        }
    }

    &.disabled {
        position: relative;

        .disabledShadow {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 9;
            background-color: rgba(255, 255, 255, 0.4);

            &:hover {
                cursor: not-allowed;
            }
        }
    }
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pdtform .input-size {
    width: 20em;
}

.amount,
.floor_amount,
.publictime,
.validendtime,
.voucher_quantity,
.voucher_quantity_limit_per_user,
.link_details {
    display: block;
}

.avatar-uploader {
    float: left;
    line-height: 0;
}

.avatar {
    width: 100%;
    max-width: 400px;
    display: block;
    /*border-radius: 4px;*/
    border: 1px solid #dcdfe6;
}

.small_danger {
    border: 1px solid;
}

.avatar-uploader-icon {
    font-size: 40px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
}

.el-upload__tip {
    background-color: #fef0f0;
    border-color: #fde2e2;
    color: #f56c6c;
    padding: 12px 10px;
    border: .3px solid;
    border-radius: 4px;
}

.el-upload__tip2 {
    max-width: 404px;
    background-color: #fef0f0;
    border-color: #fde2e2;
    color: #f56c6c;
    padding: 0px 10px;
    border: 0.3px solid;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 7px;
    line-height: 2;
}

.el-upload__tip3 {
    max-width: 460px;
    background-color: #fef0f0;
    border-color: #fde2e2;
    color: #f56c6c;
    padding: 0px 10px;
    border: 0.3px solid;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 7px;
    line-height: 2;
}
</style>
<script>
import pageconst from "@/pageconst";
import constant from "@/constant";
import draggable from 'vuedraggable'
export default {
    data() {
        return {
            uploadAction: pageconst.upload_pdt_detail_url,
            drag: false,
            dra2: false,
            dra3: false,
            FileList: [],
            FileList2: [],
            FileList3: [],
            upload_url: constant.upload_temp_img_url,
            dialogVisible: false,
            dialogImageUrl: '',
            dialogVisible2: false,
            dialogImageUrl2: '',
            dialogVisible3: false,
            dialogImageUrl3: '',
            merchantcouponinfo: {
                ActivityName: "",
                CodeMode: "1",
                VoucherType: "1",
                Amount: "",
                FloorAmount: "",
                GoodsName: '',
                PublishStartTime: "",
                PublishEndTime: '',
                Type: "1",
                ValidBeginTime: "",
                ValidEndTime: "",
                ValidDaysAfterReceive: '',
                WaitDaysAfterReceive: "",
                VoucherQuantity: "",
                VoucherQuantityLimitPerUser: "",
                LinkDetails: "",
                CustomerServiceInformation: "1",
                FullMinus: true,
                BrandName: "",
                VoucherDescription: '',
                Service: "",
                PdtImg: [],
                PdtImg2: [],
                PdtImg3: [],
            },
        }
    },
    components: {
        draggable
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
        ChangeVoucherType() {
            console.log(this.merchantcouponinfo.VoucherType);
            if (this.merchantcouponinfo.VoucherType != 1) {
                this.merchantcouponinfo.FullMinus = false;
            } else {
                this.merchantcouponinfo.FullMinus = true;
            }
        },
        imageUploadChange(file) {
            console.log("image upload change ===>");
            let isSubmit = file.raw.size / 1024 / 1024 < 2;
            if (!isSubmit) {
                this.$message.error("产品缩略图大小不能超过 2MB!");
                return false;
            }
        },
        imgUploadError(err) {
            console.log(err);
        },
        UploadSuccess(response, file) {
            console.log(response);
            console.log(file);
            if (response.error == '0') {
                var uid = file.uid;
                var name = file.name;
                var url = '';
                if (file.response && file.response == 0)
                    url = file.response.url;
                else
                    url = response.url;
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.merchantcouponinfo.PdtImg.push(obj);
            }
            console.log(this.merchantcouponinfo.PdtImg);
        },
        RemovePic(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.merchantcouponinfo.PdtImg = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]['uid'];
                var name = filelist[i]['name'];
                var url = filelist[i]['url'];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.merchantcouponinfo.PdtImg.push(obj);
            }
        },
        Preview(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else {
                this.dialogImageUrl = file.url;
            }
            this.dialogVisible = true;
        },
        UploadSuccess2(response, file) {
            console.log(response);
            console.log(file);
            if (response.error == '0') {
                var uid = file.uid;
                var name = file.name;
                var url = '';
                if (file.response && file.response == 0)
                    url = file.response.url;
                else
                    url = response.url;
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.merchantcouponinfo.PdtImg2.push(obj);
            }
            console.log(this.merchantcouponinfo.PdtImg2);
        },
        RemovePic2(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.merchantcouponinfo.PdtImg2 = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]['uid'];
                var name = filelist[i]['name'];
                var url = filelist[i]['url'];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.merchantcouponinfo.PdtImg2.push(obj);
            }
        },
        Preview2(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.dialogImageUrl2 = file.response.url;
                    console.log(this.dialogImageUrl2);
                }
            }
            else {
                this.dialogImageUrl2 = file.url;
            }
            this.dialogVisible2 = true;
        },
        UploadSuccess3(response, file) {
            console.log(response);
            console.log(file);
            if (response.error == '0') {
                var uid = file.uid;
                var name = file.name;
                var url = '';
                if (file.response && file.response == 0)
                    url = file.response.url;
                else
                    url = response.url;
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.merchantcouponinfo.PdtImg3.push(obj);
            }
            console.log(this.merchantcouponinfo.PdtImg3);
        },
        RemovePic3(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.merchantcouponinfo.PdtImg3 = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]['uid'];
                var name = filelist[i]['name'];
                var url = filelist[i]['url'];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.merchantcouponinfo.PdtImg3.push(obj);
            }
        },
        Preview3(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.dialogImageUrl3 = file.response.url;
                    console.log(this.dialogImageUrl3);
                }
            }
            else {
                this.dialogImageUrl3 = file.url;
            }
            this.dialogVisible3 = true;
        },
        onDragStart() {
            this.drag = true;
        },
        onDragEnd() {
            this.drag = false;
            console.log(this.merchantcouponinfo.PdtImg2);
        },
        onDragStart2() {
            this.drag2 = true;
        },
        onDragEnd2() {
            this.drag2 = false;
            console.log(this.merchantcouponinfo.PdtImg2);
        },
        onDragStart3() {
            this.drag3 = true;
        },
        onDragEnd3() {
            this.drag3 = false;
            console.log(this.merchantcouponinfo.PdtImg3);
        },
        onRemoveHandler(index) {
            this.merchantcouponinfo.PdtImg.splice(index, 1);
        },
        onRemoveHandler2(index) {
            this.merchantcouponinfo.PdtImg2.splice(index, 1);
        },
        onRemoveHandler3(index) {
            this.merchantcouponinfo.PdtImg3.splice(index, 1);
        },
        submitForm() {
            console.log(this.merchantcouponinfo.VoucherQuantityLimitPerUser)
            if (!Number.isInteger(this.merchantcouponinfo.VoucherQuantityLimitPerUser)) {
                console.log('不是整数')
            } else {
                console.log('整数')
            }
            if (this.merchantcouponinfo.VoucherQuantityLimitPerUser < 0) {
                console.log('小于0的数')
            }
            this.$refs['merchantcouponinfo'].validate((valid) => {
                if (valid) {
                    console.log(this.merchantcouponinfo.CodeMode)
                    if (isNaN(Number(this.merchantcouponinfo.Amount))) {
                        this.$message.error('请输入正确可抵扣金额为整数或者浮点数')
                        return false
                    }
                    if (this.merchantcouponinfo.Amount < 0.1 || this.merchantcouponinfo.Amount > 3000) {
                        this.$message.error("输入的可抵扣得金额不正确");
                        return false;
                    }
                    if (this.merchantcouponinfo.FloorAmount) {
                        if (this.merchantcouponinfo.FloorAmount < 0.1 || this.merchantcouponinfo.FloorAmount > 50000) {
                            this.$message.error('请输入可用范围内的门槛金额');
                            return false;
                        }
                    }
                    //var dateTime = new Date(0);
                    var dateBegin = new Date(this.merchantcouponinfo.PublishStartTime);
                    var dateEnd = new Date(this.merchantcouponinfo.PublishEndTime);
                    var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
                    if (dateDiff < 0) {
                        this.$message.error('券发放结束时间必须大于券发放开始时间')
                        return false
                    }
                    var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));
                    if (dayDiff > 180) {
                        this.$message.error('时间间隔已超过180天');
                        return false;
                    }
                    var ValidBeginTime = new Date(this.merchantcouponinfo.ValidBeginTime);
                    var ValidEndTime = new Date(this.merchantcouponinfo.ValidEndTime);
                    var datediff = ValidBeginTime.getTime() - ValidEndTime.getTime();
                    if (datediff >= 0) {
                        this.$message.error('券的结束时间必须大于券的开始时间');
                        return false;
                    }
                    if (this.merchantcouponinfo.Type == 2) {
                        if (!this.merchantcouponinfo.ValidDaysAfterReceive) {
                            this.$message.error("请填写需要等待天数");
                            return false;
                        }
                        if (!this.merchantcouponinfo.WaitDaysAfterReceive) {
                            this.$message.error('请填写生效后多少天可以使用');
                            return false
                        }
                    }
                    let arr = Object.values(this.merchantcouponinfo.PdtImg)
                    if (arr.length != 1) {
                        this.$message.error('请上传1张商家logo')
                        return false
                    }
                    let arr2 = Object.values(this.merchantcouponinfo.PdtImg2)
                    if (arr2.length != 1) {
                        this.$message.error('请上传1张券详情页封面图')
                        return false
                    }
                    console.log(this.merchantcouponinfo.PdtImg3);
                    let arr3 = Object.values(this.merchantcouponinfo.PdtImg3)
                    if (arr3.length == 0) {
                        this.$message.error('请至少上传1张券详细图')
                        return false
                    }
                    if (arr3.length >= 4) {
                        this.$message.error('请最多上传3张券详细图')
                    }
                    if (this.merchantcouponinfo.CodeMode == 1) {
                        console.log(this.merchantcouponinfo.VoucherQuantity)
                        if (this.merchantcouponinfo.VoucherQuantity != 0 || this.merchantcouponinfo.VoucherQuantity != '') {
                            this.$message.error('此模式下发行券的数量为0或者留空')
                            return false
                        }
                    } else {
                        if (this.merchantcouponinfo.VoucherQuantity > 0 && Number.isInteger(this.merchantcouponinfo.VoucherQuantity)) {
                            this.$message.error('此模式下发行券的数量必须大于0')
                            return false
                        }
                    }
                } else {
                    return false;
                }
            });
        },
    }
}
</script>